import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import SingleColumnBlock from "@components/singleColumnBlock"

import {
  RequestMoreInfo,
} from "@components/Forms"

const NotFoundPage = () => (
  <PageLayout>
    <SEO title="404 | Requested Page Not Found | The Soogs" />
    <SingleColumnBlock
      textPadded
      text={
        <div>
          <h1>This page you were looking for got outta here.</h1>
          <p>Try our <a href="/">homepage</a> where there is great music.</p>
          <h4>If you need to get in touch, do it!</h4>
          <RequestMoreInfo />
        </div>
      }
    />
  </PageLayout>
)

export default NotFoundPage
